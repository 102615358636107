@tailwind base;

a { -webkit-user-select: none !important; }

body {
    font-family: 'Raleway', sans-serif;
    -webkit-touch-callout: none !important; 
}

#background-image {
    height: 100%;
    background-image: url('./images/background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: overlay;
    filter: blur(15px);
    transition: 5s filter linear;
}

#background-image.deblur {
    filter: blur(0px)
}

#fingerprint-scanner {
    filter: invert(36%) sepia(28%) saturate(4797%) hue-rotate(190deg) brightness(107%) contrast(98%);
}

#fingerprint-scanner.pulse {
    animation: pulse 0.5s 2s 2 ease-in-out;
}

#fingerprint-scanner:active {
    filter: invert(82%) sepia(19%) saturate(4332%) hue-rotate(176deg) brightness(96%) contrast(106%);
}

@keyframes pulse {
    0% {
        transform: scale(1.0);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1.0);
    }
}

.h-screen-50 {
    height: 50vh;
}

.h-screen-25 {
    height: 25vh;
}

.h-screen-75 {
    height: 75vh;
}

@tailwind components;
@tailwind utilities;